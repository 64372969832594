import React from 'react';

import { ThemeProvider } from 'styled-components';

import {
  createTheme,
  ThemeProvider as ThemeMaterialUI,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { Router } from 'react-router-dom';
import GlobalStyles from '~/styles/global';

import Routes from '~/routes';
import theme from '~/styles/theme';
import { AuthProvider } from './context/auth';
import { LojaProvider } from './context/loja';
import history from './services/history';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { injectStyle } from 'react-toastify/dist/inject-style';
import ZohoDeskWidget from './components/ZohoDeskWidget';

injectStyle();

function App(): JSX.Element {
  const themeMaterial = createTheme({}, ptBR);

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ZohoDeskWidget />
      <ThemeProvider theme={theme}>
        <ThemeMaterialUI theme={themeMaterial}>
          <LojaProvider>
            <AuthProvider>
              <Router history={history}>
                <Routes />
              </Router>
              <GlobalStyles />
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </AuthProvider>
          </LojaProvider>
        </ThemeMaterialUI>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
